import './App.css';
import Home from './Pages/Home';
import PageContainer from './Pages/PageContainer'


function App() {
  return (
    <PageContainer width="100%" backgroundColor="black" >
      <Home/>
      </PageContainer>
  );
}

export default App;
