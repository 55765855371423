import React from 'react';
import logo from '../Content/logo.svg'

const Home = () => {
  return (
    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100vh' }}>
      <img 
        src={logo}
        alt="Logo" 
        style={{ width: '60%' }} 
      />
    </div>
  );
};

export default Home;