import React from 'react';

const Container = ({ children, width = '100%', backgroundColor = '#ffff' }) => {
  return (
    <div
      style={{
        width,
        backgroundColor,
      }}
    >
      {children}
    </div>
  );
};

export default Container;